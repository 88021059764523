import React from "react"
import styled from "styled-components"
import OverviewSection from "./OverviewSection"

const MainContainer = styled.div`
    margin-top: 90px;

    @media screen and (max-width: 750px) {
        margin-top: 75px;
    }
` 

export default ({ overviewData, isMobile }) => {
    
    const fav = overviewData.node.overview_favourites;

    const getName = (index, type) => {
        if(type === 'project') {
            return fav[index].overview_favourite.project_title[0].text
        } else if (type === 'event') {
            return fav[index].overview_favourite.event_title[0].text
        } else {
            return ""
        }
    }

    const getKind = (index) => {
        return fav[index].overview_favourite._meta.type
    }

    return (
        <MainContainer>
            <OverviewSection kind="odd"
                linkKind={getKind(0)}
                linkName={getName(0, getKind(0))}
                linkURL={`/${getKind(0)}s/${fav[0].overview_favourite._meta.uid}`}
                // photoSharp.childImageSharp.fluid
                firstImgFluid={fav[0].overview_favourite.photos[0].photoSharp.childImageSharp.fluid}
                firstImgAlt={fav[0].overview_favourite.photos[0].photo.alt}
                secondImgFluid={fav[0].overview_favourite.photos[1].photoSharp.childImageSharp.fluid}
                secondImgAlt={fav[0].overview_favourite.photos[1].photo.alt}
                isMobile={isMobile}>
            </OverviewSection>

            <OverviewSection kind="even"
                linkKind={getKind(1)}
                linkName={getName(1, getKind(1))}
                linkURL={`/${getKind(1)}s/${fav[1].overview_favourite._meta.uid}`}
                // photoSharp.childImageSharp.fluid
                firstImgFluid={fav[1].overview_favourite.photos[0].photoSharp.childImageSharp.fluid}
                firstImgAlt={fav[1].overview_favourite.photos[0].photo.alt}
                secondImgFluid={fav[1].overview_favourite.photos[1].photoSharp.childImageSharp.fluid}
                secondImgAlt={fav[1].overview_favourite.photos[1].photo.alt}
                isMobile={isMobile}>
            </OverviewSection>

            <OverviewSection kind="odd"
                linkKind={getKind(2)}
                linkName={getName(2, getKind(2))}
                linkURL={`/${getKind(2)}s/${fav[2].overview_favourite._meta.uid}`}
                // photoSharp.childImageSharp.fluid
                firstImgFluid={fav[2].overview_favourite.photos[0].photoSharp.childImageSharp.fluid}
                firstImgAlt={fav[2].overview_favourite.photos[0].photo.alt}
                secondImgFluid={fav[2].overview_favourite.photos[1].photoSharp.childImageSharp.fluid}
                secondImgAlt={fav[2].overview_favourite.photos[0].photo.alt}
                isMobile={isMobile}>
            </OverviewSection>    
        </MainContainer>
    )
}
