import React from "react"
import { graphql } from "gatsby"
// import SEO from "../components/SEO"
import LandingPhoto from '../components/LandingPhoto'
import Overview from '../components/Overview'
import Layout from '../layouts/Layout'

export const query = graphql`
{
  prismic {
    allHomepages {
      edges {
        node {
          landing_photo_desktop
          landing_photo_desktopSharp {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          landing_photo_mobile
          landing_photo_mobileSharp {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          overview_favourites {
            overview_favourite {
              ... on PRISMIC_Project {
                _meta {
                  uid
                  type
                }
                project_title
                photos {
                  photo
                  photoSharp {
                    childImageSharp {
                      fluid(maxWidth: 620, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Event {
                _meta {
                  uid
                  type
                }
                event_title
                photos {
                  photo
                  photoSharp {
                    childImageSharp {
                      fluid(maxWidth: 620, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allAbouts {
      edges {
        node {
          short_footer_text
        }
      }
    }
  }
}
`

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: null,
        }

        // this could be done using hooks -> see about page
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }
        
    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
        this.updateWindowDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    render() {
        const homepageData = this.props.data.prismic.allHomepages.edges.slice(0, 1).pop()
        const footerData = this.props.data.prismic.allAbouts.edges.slice(0, 1).pop()
        const currentLocation = this.props.location;
        if (!homepageData || !footerData) return null
        
        return (
            <>
                <div className="max-width-1920">
                { this.state.screenWidth > 900 &&
                    <LandingPhoto fluid={homepageData.node.landing_photo_desktopSharp.childImageSharp.fluid} location={currentLocation}></LandingPhoto>
                }
                { this.state.screenWidth <= 900 &&
                    <LandingPhoto fluid={homepageData.node.landing_photo_mobileSharp.childImageSharp.fluid} location={currentLocation}></LandingPhoto>
                }
                </div>
                <Layout
                location={currentLocation}
                headerPosition="absolute"
                footerShortText={footerData.node.short_footer_text[0].text}
                footerLinksArray={[
                    {
                    name: 'Projects',
                    URL: '/projects/'
                    },
                    {
                    name: 'Events',
                    URL: '/events/'
                    },
                    {
                    name: 'Diary',
                    URL: '/diary/'
                    }
                ]}>
                <div>
                    {this.state.screenWidth > 750 &&
                      <Overview overviewData={homepageData} isMobile={false}></Overview>
                    }
                    {this.state.screenWidth <= 750 &&
                      <Overview overviewData={homepageData} isMobile={true}></Overview>
                    }
                </div>
                </Layout>
            </>
            )
        }
    }

export default Index;

