import React from "react"
import styled from "styled-components"
import LinkTo from './LinkTo'
import Img from 'gatsby-image'

const SectionTag = styled.section`
    margin-bottom: 120px;

    @media screen and (max-width: 750px) {
        margin-bottom: 75px;
    }
`
const FirstImageOdd = styled.div`
    z-index: 1;
    width: 27.7vw;
    max-width: 550px;
    position: relative;
    right: -3.5vw;
    vertical-align: top;
    float: left;

    @media (max-width: 1500px) {
        width: 400px;
        max-width: 400px;
    }

    @media (max-width: 1200px) {
        width: 30vw;
        max-width: 350px;
    }

    @media screen and (max-width: 750px) {
        position: static;
        width: calc( 100vw - 8vw );
        max-width: calc( 100vw - 8vw );
    }
`
const FirstImageEven = styled.div`
    z-index: 1;
    width: 27.7vw;
    max-width: 550px;
    position: relative;
    right: -3.5vw;
    vertical-align: top;
    margin-top: 25%;
    float: left;

    @media (max-width: 1500px) {
        width: 400px;
        max-width: 400px;
    }

    @media (max-width: 1200px) {
        width: 30vw;
        max-width: 350px;
    }

    @media screen and (max-width: 750px) {
        position: static;
        width: calc( 100vw - 8vw );
        max-width: calc( 100vw - 8vw );
        margin-top: 0;
    }
`

const SecondImageOdd = styled.div`
    z-index: 0;
    width: 27.7vw;
    max-width: 550px;
    position: relative;
    left: -3.5vw;
    margin-top: 25%;
    vertical-align: top;
    float: left;

    @media (max-width: 1500px) {
        width: 400px;
        max-width: 400px;
    }

    @media (max-width: 1200px) {
        width: 30vw;
        max-width: 350px;
    }
`
const SecondImageEven = styled.div`
    z-index: 0;
    width: 27.7vw;
    max-width: 550px;
    position: relative;
    left: -3.5vw;
    vertical-align: top;
    float: left;

    @media (max-width: 1500px) {
        width: 400px;
        max-width: 400px;
    }

    @media (max-width: 1200px) {
        width: 30vw;
        max-width: 350px;
    }
`

const PhotoContainer = styled.div`
    display: flex;
    justify-content: space-around;
`

const LinkContainerOdd = styled.div`
    position: relative;
    top: -48px;
    left: 16%;
    display: inline-block;

    @media screen and (max-width: 1000px) {
        left: 12%;
    }
    @media screen and (max-width: 900px) {
        left: 10%;
    }

    @media screen and (max-width: 750px) {
        position: static;
        margin: 15px 4vw 0;
    }
`

const LinkContainerEven = styled.div`
    position: relative;
    top: -48px;
    left: 69%;
    display: inline-block; 

    @media screen and (max-width: 1000px) {
        left: 64%;
    }
    @media screen and (max-width: 900px) {
        left: 62%;
    }
    
    @media screen and (max-width: 750px) {
        position: static;
        margin: 15px 4vw 0;
    }
`

const LinkKind = styled.p`
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 2px;
    
`

const OverviewSection = ({ kind, linkKind, linkName, linkURL, firstImgFluid, firstImgAlt, secondImgFluid, secondImgAlt, isMobile }) => {
    
    const capitalizeFirstLetter = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return (
        <SectionTag>
            <PhotoContainer>
                <div>
                    {kind === "odd" &&
                        <React.Fragment>
                            <FirstImageOdd><Img fluid={firstImgFluid} alt={firstImgAlt}></Img></FirstImageOdd>
                            {!isMobile && 
                                <SecondImageOdd><Img fluid={secondImgFluid} alt={secondImgAlt}></Img></SecondImageOdd>
                            }
                        </React.Fragment>
                    }
                    {kind === "even" &&
                        <React.Fragment>
                            <FirstImageEven><Img fluid={firstImgFluid} alt={firstImgAlt}></Img></FirstImageEven>
                            {!isMobile && 
                                <SecondImageEven><Img fluid={secondImgFluid} alt={secondImgAlt}></Img></SecondImageEven>
                            }
                        </React.Fragment>
                    }

                </div>
            </PhotoContainer>
            {kind === "odd" &&
                <LinkContainerOdd>
                    <LinkKind>{capitalizeFirstLetter(linkKind)}</LinkKind>
                    <LinkTo linkURL={linkURL} linkName={linkName}></LinkTo>
                </LinkContainerOdd>
            }

            {kind === "even" &&
                <LinkContainerEven>
                    <LinkKind>{capitalizeFirstLetter(linkKind)}</LinkKind>
                    <LinkTo linkURL={linkURL} linkName={linkName}></LinkTo>
                </LinkContainerEven>
            }
        </SectionTag>
    )
}

export default OverviewSection
