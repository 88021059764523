import React from "react"
import Img from 'gatsby-image'
import Transition from './Transition'

export default ({ fluid, location }) => {
  return (
    <Transition location={location}>
      <div className="max-width-1920">
        <Img
          fluid={fluid}
          style={{
            height: '100vh',
            objectFit: 'cover',
            zIndex: '0'
          }}>
        </Img>
      </div>
    </Transition>
  )
}

